<template>
  <ez-content>
    <ez-container>
      <ez-facts
        v-if="facts.length > 0"
        :limit="facts.length"
        :max="facts.length"
        :fluid="true"
        :items="facts"
      />
      <ez-box>
        <template slot="body">
          <ez-sub-header>
            <template slot="title">
              <div v-html="title"></div>
            </template>
          </ez-sub-header>
          <template v-if="!loading">
            <app-stub-box
              v-if="empty"
              :image="require('../assets/icons/snooze.svg')"
              :title="$t('page-activity.no-data-title')"
              :description="$t('page-activity.no-data-description')"
            />
            <ez-table v-if="!empty" class="table" :data="displayItems" stripe>
              <div slot="empty">{{ $t("no-data") }}</div>
              <ez-table-column prop="time" sortable :label="$t('page-activity.fields.time')">
                <template slot-scope="scope">{{scope.row.date | userTime}}</template>
              </ez-table-column>
              <ez-table-column prop="url" sortable :label="$t('page-activity.fields.url')">
                <template slot-scope="scope">
                  <ez-link :href="prettyLink(scope.row.url)" target="_blank">
                    {{ prettyLink(scope.row.url) }}
                  </ez-link>
                </template>
              </ez-table-column>
              <ez-table-column
                prop="parent"
                sortable
                :label="$t('page-activity.fields.entity-name')"
              >
                <template slot-scope="scope">{{scope.row.parent}}</template>
              </ez-table-column>
              <ez-table-column
                prop="child"
                sortable
                :label="$t('page-activity.fields.sub-entity-name')"
              >
                <template slot-scope="scope">{{scope.row.child}}</template>
              </ez-table-column>
              <ez-table-column prop="source" sortable :label="$t('page-activity.fields.source')">
                <template slot-scope="scope">{{scope.row.source}}</template>
              </ez-table-column>
            </ez-table>
            <ez-pagination
              v-if="displayItems && displayItems.length > 0"
              :page="pageNumber"
              :page-count="totalPages"
              :page-range="pageRange"
              :limit="pageSize"
              :limit-options="limitOptions"
              @change="onPaginationChange"
            />
          </template>
        </template>
      </ez-box>
    </ez-container>
  </ez-content>
</template>

<script>
import get from 'lodash/get';
import contactMixin from '@/mixins/contact';
import iconOrderForm from '@/assets/icons/order-form.svg';
import iconGlasses from '@/assets/icons/glasses.svg';
import userTime from '@/services/formatters/userTime';
import * as injectNames from '../services/names';
import * as getters from '../store/page-activity/getters';
import * as actions from '../store/page-activity/actions';
import format from './Timeline/format';

const STORE_PREFIX = 'pageActivity';

export default {
  dependencies: [injectNames.PAGE_ACTIVITY_API],
  mixins: [contactMixin],
  filters: {
    userTime,
  },
  data() {
    return {
      page: 1,
      pageCount: 15,
      pageRange: 7,
      limit: 20,
      limitOptions: [2, 5, 10, 20],
      pageTitle: this.$t('header.menuLinks.activity'),
      title: this.$t('page-activity.title'),
    };
  },
  created() {
    // this.getData({ limit: this.limit });
    // this.getFacts();

    // load contact's page activities
    this.loadItems();
  },
  methods: {
    // ...mapActions("contacts_/activity", ["getData", "getFacts"]),
    onChange({ limit, page }) {
      this.getData({ limit, page });
    },

    // load data
    loadItems(params = {}) {
      const actualParams = {
        contactId: this.currentContactId,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...params,
      };
      this.$store.dispatch(
        `${STORE_PREFIX}/${actions.GET_ITEMS}`,
        actualParams,
      );
    },

    // sorting
    onSortChange(value) {
      const prop = value.prop == null ? 'name' : value.prop;
      const order = value.order == null ? 'ascending' : value.order;
      this.loadItems({ pageNumber: 1, sortProp: prop, sortOrder: order });
    },

    // pagination
    onPaginationChange({ limit, page }) {
      this.loadItems({ pageSize: limit, pageNumber: page });
    },

    // pretty link for output (remove query string part)
    prettyLink(url) {
      const originalUrl = url || '';
      const questionMarkIndex = originalUrl.indexOf('?');
      const result = questionMarkIndex !== -1
        ? originalUrl.substring(0, questionMarkIndex)
        : originalUrl;
      return result;
    },
  },
  computed: {
    facts() {
      if (this.currentContact == null) {
        return [];
      }
      return [
        {
          id: 1,
          header: get(
            this.currentContact,
            'attributes.unique-page-visits',
            'N/A',
          ),
          icon: iconGlasses,
          text: this.$t('page-activity.widgets.unique-page-visits'),
        },
        {
          id: 2,
          header: get(
            this.currentContact,
            'attributes.order-form-visits',
            'N/A',
          ),
          icon: iconOrderForm,
          text: this.$t('page-activity.widgets.order-form-visits'),
        },
      ];
    },

    // items to display in table and paging props
    items() {
      const items = this.$store.getters[`${STORE_PREFIX}/${getters.ITEMS}`];
      return items;
    },
    displayItems() {
      // TODO: process items for display
      return this.items.map((item) => {
        const { id } = item;
        return {
          id,
          date: get(item, 'attributes.dt-create'),
          url: get(item, 'attributes.url'),
          parent: format(
            '%relationships.funnel.attributes.name %relationships.website-id.attributes.name',
            item,
          ).trim(),
          child: format(
            '%relationships.step.attributes.name',
            item,
          ).trim(),
          source: get(item, 'attributes.source'),
        };
      });
    },
    totalItems() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.TOTAL_ITEMS}`];
    },
    empty() {
      return this.totalItems === 0;
    },
    totalPages() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.TOTAL_PAGES}`];
    },
    loading() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.LOADING}`];
    },
    pageSize() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.PAGE_SIZE}`];
    },
    pageNumber() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.PAGE_NUMBER}`];
    },
    enabled() {
      return this.currentContact != null;
    },
  },
};
</script>

const get = require('lodash/get');

// eslint-disable-next-line
const reg = /%([A-Za-z\._\-(\[\d+\])])+/gi;

const processTemplate = (template, data, replacer) => template.replace(reg, (match) => {
  const path = match.replace('%', '');
  let value = get(data, path, '');
  if (replacer) {
    value = replacer(value, path);
  }
  return value;
});

export default processTemplate;
